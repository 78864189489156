
@import '../../styles/components/button';

.errorBoundary {
  background-color: #ebf8f7;
  padding-left: 3rem;
  padding-top: 2rem;
  height: calc(100vh - 6.4rem);
}

.heading {
  font-size: 2.5rem;
  
}
.headingLink{
  @extend .buttonPrimary;
  display: inline-flex;
  margin-top: 1rem;
}
