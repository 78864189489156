$font-family: 'Oxygen', sans-serif;

//font-weight
$font-weight-base: 400;
$font-weight-bold: 700;

//text-colors
//landing (homepage)
$text-color-heading-homepage: #212121;
$text-color-subheading-homepage: #2b2d40;
$text-color-subheading-accent-homepage: #bf4849;
$text-color-primary-homepage: #858aaa;

//all app styles text-color
$text-color-primary: #2b2d40;
$text-color-secondary: #8e99ac;
$text-color-description:#616161;
$text-color-form: #757575;
$text-color-accent: #bf4849;
$text-color-label:#C92B32;
$text-color-error: #e95c7b;
$text-color-white: #fff;
$text-color-graphs-description: #858AAA;

$text-color-button-primary: #ffffff;
$text-color-button-secondary: #2b2d40;

//bg-colors
$background-color-primary: #ffffff;
$background-color-secondary: #EBF8F7;

$background-color-success: #55C1A8;
$background-color-fail: #C92B32;

$background-color-table-heading-primary: #F3D0D2;
$background-color-table-heading-secondary: #96DAD5;
$background-color-table-stripe:#F5F5F5;

$background-color-badge:#EBF8F7;
$background-color-notification-primary:#FFE4AF;
$background-color-notification-secondary:#D7F1EF;

$background-color-button-primary:#C92B32;
$background-color-button-secondary:#ffffff;

$background-color-button-primary-hover:#9b2d30;
$background-color-button-secondary-hover:#e8e8e8;

