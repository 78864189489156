@import '../utils/variables';

html {
  font-size: 62.5%; // 1rem === 10px
  font-family: $font-family;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  display: block;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.heading {
  margin: 0;
  font-size: 2.6rem;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 1.53;
  color: $text-color-heading-homepage;
}
.text {
  font-size: 1.8rem;
  font-weight: $font-weight-base;
  line-height: 1.77;
  color: $text-color-form;
}
.error {
  color: $text-color-error;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 1.77;
  font-weight: $font-weight-base;
  &:first-letter {
    text-transform: capitalize;
  }
}
.errorIcon {
  display: inline-block;
  margin-right: 1rem;
}
.label {
  color: $text-color-primary;
  font-size: 1.8rem;
  line-height: 1.77;
  font-family: $font-family;
  font-weight: $font-weight-bold;
}
.labelRed {
  @extend .label;
  color: $text-color-label;
}
.textLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 1.43;
  color: $text-color-label;
  cursor: pointer;
  &:hover {
    color: $background-color-button-primary-hover;
  }
}
