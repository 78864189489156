@import '../utils/mixins';
@import '../utils/variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
  font-family: $font-family;
  outline: none;
  border-radius: 0.4rem;
  border: none;
  text-decoration: none;
  &::first-letter {
    text-transform: capitalize;
  }
}
.buttonPrimary {
  @extend .button;
  padding: 0.8rem 1.6rem;
  font-size: 2.1rem;
  line-height: 1.52;
  color: $text-color-button-primary;
  background-color: $background-color-button-primary;
  font-weight: $font-weight-bold;
  transition: 0.1s ease-in-out 0s;
  &:hover,
  &:active {
    background-color: $background-color-button-primary-hover;
  }
  &:active {
    transform: scale(1.05);
  }
  &:disabled {
    background-color: #E0E0E0;
  }
  @include mq(xxl) {
    font-size: 1.8rem;
  }
}
.buttonSecondary {
  @extend .button;
  padding: 1rem 4.5rem;
  font-size: 1.6rem;
  line-height: 1.75;
  color: $text-color-button-secondary;
  background-color: $background-color-button-secondary;
  border: 1px solid $text-color-button-secondary;
  font-weight: $font-weight-base;
  transition: 0.1s ease-in-out 0s;
  &:hover,
  &:active {
    background-color: $background-color-button-secondary-hover;
  }
  &:active {
    transform: scale(1.05);
  }
  &:disabled {
    background-color: #E0E0E0;
    color: $text-color-button-primary;
    border:none;
  }
  @include mq(xxl) {
    font-size: 1.5rem;
  }
}
